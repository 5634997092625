exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adult-js": () => import("./../../../src/pages/adult.js" /* webpackChunkName: "component---src-pages-adult-js" */),
  "component---src-pages-child-js": () => import("./../../../src/pages/child.js" /* webpackChunkName: "component---src-pages-child-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-greeting-js": () => import("./../../../src/pages/greeting.js" /* webpackChunkName: "component---src-pages-greeting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-js": () => import("./../../../src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-takarazuka-js": () => import("./../../../src/pages/takarazuka.js" /* webpackChunkName: "component---src-pages-takarazuka-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */),
  "component---src-pages-voice-js": () => import("./../../../src/pages/voice.js" /* webpackChunkName: "component---src-pages-voice-js" */)
}

